<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="导入经销商信息" width="840px" @closed="handleClosed">
    <div :class="{layout_disabled: importing}">
      <div class="h">
        <el-input v-model="query.code" :maxlength="20" placeholder="输入ERP编码搜索" style="width: 180px;" />&nbsp;
        <el-input v-model="query.name" :maxlength="20" placeholder="输入经销商名称搜索" style="width: 180px;" />&nbsp;
        <el-button size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      </div>
      <div class="h">
        <div class="flex">
          <div class="padding-20-0 h c">
            <b>经销商列表</b>
          </div>
          <el-table ref="list" :data="data" row-key="erpId" border v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="35" reserve-selection />
            <el-table-column prop="erpId" label="ERP编码" width="100" />
            <el-table-column prop="fullName" label="经销商名称" v-if="erpType!=='serta'"/>
            <el-table-column prop="name" label="经销商简称" v-if="erpType!=='serta'"/>
            <el-table-column prop="name" label="经销商名称" v-if="erpType==='serta'"/>
          </el-table>
        </div>
        <div class="v" style="margin-left: 20px;">
          <div class="padding-20-0">
            <b>导入选项</b>
          </div>
          <div class="v padding-10-0">
            <b>经销商类型</b>
            <div class="fc-g padding-10-0">将所选经销商的类型设置为：</div>
            <quick-select ref="levels" v-model="setting.level" filterable url="api/distributorLeve/list" placeholder="请选择经销商类型" style="width: 150px" auto-select-first-option :clearable="false" v-if="dialog" />            
          </div>
          <div class="v padding-10-0">
          <b>所属区域</b>
            <div class="fc-g padding-10-0">将所选经销商的区域设置为：</div>
            <tree-picker v-model="setting.areaId" url="api/area" flat style="width: 150px" clearable v-if="dialog"/>
          </div>
        </div>
      </div>
    </div>

    <div slot="footer" class="h c">
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, jumper" @size-change="sizeChange" @current-change="pageChange" />
      <div class="flex"></div>
      <el-button type="primary" :loading="importing" :disabled="!selection || !selection.length || !setting.level" @click="handleSubmit">开始导入</el-button>
      <el-button @click="dialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import initData from "@/mixins/initData";
import { batchImport } from "@/api/distributor";

export default {
  mixins: [initData],
  data() {
    return {
      importing: false,
      dialog: false,
      enterpriseType: null,
      erpType:null,
      selection: null,
      setting: {
        level: null,
        areaId:null,
      },
      query:{
        name:null,
        code:null,
      }
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/distributor/batchGet";
      this.params = Object.assign({}, this.query);
      return true;
    },
    handleClosed() {
      this.selection = null;
      if(this.query){
        this.query.name="";
        this.query.code = "";
      }
      this.$refs.list && this.$refs.list.clearSelection();
    },
    handleSelectionChange(sel) {
      this.selection = sel;
    },
    handleSubmit() {
      if (this.selection && this.selection.length && this.setting.level) {
        this.selection.forEach(o => {
          o.level = this.setting.level;
          o.areaId = this.setting.areaId;
          o.enterpriseType = this.enterpriseType;
        });
        this.importing = true;
        batchImport(this.selection)
          .then(res => {
            this.$notify({
              title: "导入经销商成功",
              type: "success",
              duration: 2500
            });
            this.$parent.init();
            this.dialog = false;
          })
          .finally(_ => {
            this.importing = false;
          });
      }
    },
    show(v,erpType) {
      this.enterpriseType = v;
      this.erpType = erpType;
      this.dialog = true;
      this.toQuery();
    }
  }
};
</script>